<template>
  <div>
    <a
      @click="fnShowModal()"
      class="p-2"
      data-toggle="tooltip"
      data-placement="top"
      title="Edit user"
      ><i class="far fa-edit text-info"></i
    ></a>

    <b-modal ref="userModal" hide-footer :title="title" size="xl">
      <div class="d-block">
        <form v-on:submit.prevent="fnUpdateUser">
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div
            class="row"
            v-if="loginUserObj.role_id == 1 || loginUserObj.role_id == 2"
          >
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <select
                class="form-control form-group"
                v-model="userData.role_id"
                v-if="
                  userData.role_id == 1 ||
                  userData.role_id == 2 ||
                  userData.role_id == 4 ||
                  userData.role_id == 6 ||
                  userData.role_id == 10
                "
              >
                <option value="1">Admins</option>
                <option value="2">Staff</option>
                <option value="4">Corporate manager</option>
                <option value="6">Partner</option>
                <option value="10">Not yet decide</option>
              </select>
              <label class="floatingLabel" for="firstName">User type</label>
              <div class="invalid-feedback" v-if="errors.role_id">
                {{ errors.role_id[0] }}
              </div>

              <div
                v-if="
                  userData.role_id == 3 ||
                  userData.role_id == 5 ||
                  userData.role_id == 9 ||
                  userData.role_id == 7 ||
                  userData.role_id == 8
                "
              >
                <div class="form-control form-group">
                  <span v-if="userData.role_id == 3">Individual users</span>
                  <span v-if="userData.role_id == 5">Corporate staff</span>
                  <span v-if="userData.role_id == 9">Corporate customer</span>
                  <span v-if="userData.role_id == 7">Partner staff</span>
                  <span v-if="userData.role_id == 8">Partner customer</span>
                </div>
                <label class="floatingLabel" for="firstName">User type</label>
              </div>
            </div>
          </div>

          <div
            class="row"
            v-if="
              userData.role_id == 4 ||
              userData.role_id == 6 ||
              userData.role_id == 10
            "
          >
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group mb-3">
                <input
                  class="form-control"
                  type="text"
                  id="business_name"
                  autocomplete="off_business_name"
                  placeholder=" "
                  v-model="userData.business_name"
                />
                <label class="floatingLabel" for="firstName"
                  >Business name</label
                >
                <div class="invalid-feedback" v-if="errors.business_name">
                  {{ errors.business_name[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="office_code"
                  autocomplete="off_office_code"
                  placeholder=" "
                  v-model="userData.office_code"
                />
                <label class="floatingLabel" for="office_code"
                  >Office code</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="position_title"
                  autocomplete="off_position_title"
                  placeholder=" "
                  v-model="userData.position_title"
                />
                <label class="floatingLabel" for="position_title"
                  >Position title</label
                >
                <div class="invalid-feedback" v-if="errors.position_title">
                  {{ errors.position_title[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="userData.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="userData.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="userData.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="userData.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <select class="form-control" v-model="userData.gender_code">
                  <option
                    :value="item.value"
                    v-for="item in optionsGender"
                    :key="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <label class="floatingLabel vueBootSelect" for="position_title"
                  >Gender</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group multiselect">
                <b-form-select
                  value-field="name"
                  text-field="name"
                  :options="getSettingAll.country"
                  v-model="userData.country_of_birth"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >Country of birth</label
                >
                <div class="invalid-feedback" v-if="errors.country_of_birth">
                  {{ errors.country_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="email"
                  autocomplete="off_email"
                  placeholder=" "
                  v-model="userData.email"
                />
                <label class="floatingLabel" for="email">Email</label>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <!-- Int Tell Country -->
              <div class="form-group multiselect">
                <b-form-select
                  value-field="dial_code"
                  text-field="name"
                  placeholder=" "
                  :options="optionsIntTel"
                  v-model="userData.int_tel_code"
                >
                </b-form-select>
                <label class="floatingLabel vueBootSelect"
                  >Country<span class="fs-700 text-danger">
                    (Mobile number)</span
                  ></label
                >
                <div class="invalid-feedback" v-if="errors.int_tel_code">
                  {{ errors.int_tel_code[0] }}
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder=" "
                  v-model="userData.mobile_no"
                />
                <label class="floatingLabel"
                  >Mobile number
                  <span class="fs-700 text-danger"
                    >(Without country code)</span
                  ></label
                >
                <div class="invalid-feedback" v-if="errors.mobile_no">
                  {{ errors.mobile_no[0] }}
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-2 col-12 mb-3">
              <b-form-checkbox
                id="checkbox-1"
                v-model="userData.status"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                :disabled="isUserCanChange()"
              >
                Status
              </b-form-checkbox>
            </div>
          </div>

          <!-- Current address details -->
          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Current address details</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="unit_no"
                  autocomplete="off_unit_no"
                  placeholder=" "
                  v-model="userData.unit_no"
                />
                <label class="floatingLabel" for="unit_no">Unit number</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street_no"
                  autocomplete="off_street_no"
                  placeholder=" "
                  v-model="userData.street_no"
                />
                <label class="floatingLabel" for="street_no mb-3"
                  >Street number</label
                >
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="street"
                  autocomplete="off_street"
                  placeholder=" "
                  v-model="userData.street"
                />
                <label class="floatingLabel" for="street">Street</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="suburb"
                  autocomplete="off_suburb"
                  placeholder=" "
                  v-model="userData.suburb"
                />
                <label class="floatingLabel" for="suburb">Suburb</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <b-form-select
                v-model="userData.state_code"
                :options="optionsState"
              >
              </b-form-select>
              <label class="floatingLabel vueBootSelect" for="state"
                >State</label
              >
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12 mb-3">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="postCode"
                  autocomplete="off_postCode"
                  placeholder=" "
                  v-model="userData.post_code"
                />
                <label class="floatingLabel" for="postCode">Post code</label>
              </div>
            </div>
          </div>

          <!-- Stake holder settings -->
          <div
            class="row mt-5"
            v-if="
              userData.role_id == 4 &&
              (loginUserObj.role_id == 1 || loginUserObj.role_id == 2)
            "
          >
            <div class="col-12">
              <p class="text-info">Stakeholder settings (Corporate manager)</p>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-3">
              <!-- 1: no show
              0: show
              '':show -->
              <b-form-checkbox
                id="hide_view_end_customer_data"
                v-model="
                  userData.stakeholder_settings.hide_view_end_customer_data
                "
                name="hide_view_end_customer_data"
                value="1"
                unchecked-value="0"
              >
                Hide view end customer data ?
              </b-form-checkbox>
              <!-- 1: no show
              0: show
              '':show -->
              <b-form-checkbox
                id="allow_end_customer_to_view_and_notify_check_complete"
                v-model="
                  userData.stakeholder_settings
                    .allow_end_customer_to_view_and_notify_check_complete
                "
                name="allow_end_customer_to_view_and_notify_check_complete"
                value="1"
                unchecked-value="0"
              >
                Allow end customer to view and notify check complete ?
              </b-form-checkbox>
            </div>
          </div>

          <hr />
          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">
              <b-spinner small v-if="loading" class="mr-2"></b-spinner> Update
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../../services/message.service";
import * as userAdminService from "../../../services/userAdmin.service";
import {
  intTel,
  optionsGender,
  optionsState,
} from "../../../services/data.service";
import * as authService from "../../../services/auth.service";

export default {
  name: "ModalUserEdit",
  props: ["userType", "propsData", "userCategoryId", "title"],
  components: {},
  data() {
    return {
      errors: {},
      loginUserObj: {},
      optionsIntTel: intTel,
      loading: false,
      userData: {
        id: "",
        parent_id: "",
        business_name: "",
        office_code: "",
        position_title: "",
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        role_id: "",
        date_of_birth: "",
        country_of_birth: "AUS",
        email: "",
        int_tel_code: "",
        mobile_no: "",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        stakeholder_settings: {
          hide_view_end_customer_data: 0,
          allow_end_customer_to_view_and_notify_check_complete: 0,
        },
        status: true,
      },
      optionsGender: optionsGender,
      optionsState: optionsState,
    };
  },
  computed: {
    ...mapGetters([
      "getSettingAll",
      "getUserPaginate",
      "getUserCorporatePaginate",
      "getUserSingle",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchUserListPaginate",
      "fetchUserCorporateListPaginate",
      "fetchUserSingle",
    ]),
    fnShowModal() {
      this.loginUserObj = authService.getUserFromToken();

      this.fetchUserSingle(this.propsData.id);
      this.showModal();
    },
    hideModal() {
      this.$refs.userModal.hide();
    },
    showModal() {
      this.$refs.userModal.show();
    },

    fnUpdateUser: async function () {
      this.errors = {};

      try {
        this.loading = true;
        await userAdminService.update(this.userData.id, this.userData);

        if (
          this.userType == "corporate_customer" ||
          this.userType == "corporate_staff"
        ) {
          this.fetchUserCorporateListPaginate(this.getUserCorporatePaginate);
        } else if (
          this.userType == "partner_customer" ||
          this.userType == "partner_staff"
        ) {
          this.fetchUserCorporateListPaginate(this.getUserCorporatePaginate);
        } else {
          this.fetchUserListPaginate(this.getUserPaginate);
        }

        messageService.fnSweetAlertSuccessToast("Successfully updated");

        this.userData = {
          id: "",
          parent_id: "",
          business_name: "",
          office_code: "",
          position_title: "",
          first_name: "",
          middle_name: "",
          last_name: "",
          gender_code: "M",
          date_of_birth: "",
          country_of_birth: "AUS",
          email: "",
          int_tel_code: "",
          mobile_no: "",
          unit_no: "",
          street_no: "",
          street: "",
          suburb: "",
          state_code: "",
          post_code: "",
          stakeholder_settings: {
            hide_view_end_customer_data: 0,
            allow_end_customer_to_view_and_notify_check_complete: 0,
          },
          status: false,
        };
        this.hideModal();
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "User - update",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "User - update",
              "Something wrong"
            );
            break;
        }

        this.loading = false;
      }
    },

    isUserCanChange() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    getUserSingle(val) {
      this.userData.id = val.id;
      this.userData.parent_id = val.parent_id;
      this.userData.business_name = val.business_name;
      this.userData.office_code = val.office_code;
      this.userData.position_title = val.position_title;
      this.userData.gender_code = val.gender_code;
      this.userData.first_name = val.first_name;
      this.userData.middle_name = val.middle_name;
      this.userData.last_name = val.last_name;
      this.userData.role_id = val.role_id;
      this.userData.date_of_birth = val.date_of_birth;
      this.userData.country_of_birth = val.country_of_birth;
      this.userData.email = val.email;
      this.userData.int_tel_code = val.int_tel_code;
      this.userData.mobile_no = val.mobile_no;
      this.userData.unit_no = val.unit_no;
      this.userData.street_no = val.street_no;
      this.userData.street = val.street;
      this.userData.suburb = val.suburb;
      this.userData.state_code = val.state_code;
      this.userData.post_code = val.post_code;

      if (
        val.get_stakeholder_settings &&
        val.get_stakeholder_settings &&
        val.get_stakeholder_settings.settings &&
        val.get_stakeholder_settings.settings.hide_view_end_customer_data
      ) {
        this.userData.stakeholder_settings.hide_view_end_customer_data =
          val.get_stakeholder_settings.settings.hide_view_end_customer_data;
      }

      if (
        val.get_stakeholder_settings &&
        val.get_stakeholder_settings &&
        val.get_stakeholder_settings.settings &&
        val.get_stakeholder_settings.settings
          .allow_end_customer_to_view_and_notify_check_complete
      ) {
        this.userData.stakeholder_settings.allow_end_customer_to_view_and_notify_check_complete =
          val.get_stakeholder_settings.settings.allow_end_customer_to_view_and_notify_check_complete;
      }

      this.userData.status = val.status;
    },
  },
};
</script>
